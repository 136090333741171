<template>
  <div class="p-0">
    <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="$route.query.tab">
      <template #detail>
        <Order :showBtn="false"
          orderStore="customer_orders"
          orderItemStore="order_items"
        />
      </template>
      <template #logs>
        <SCardLog :logableId="id" />
      </template>
      <template #transactions>
        <SCardTransaction :objectableId="id" />
      </template>
      <template #shipment_info>
        <SCardShipmentInfo :orderId="id" :userId="detail.customer_id" />
      </template>
      <template #tracking>
        <Tracking :trackings="detail.related_trackings" />
      </template>
    </TTabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../../../sale/views/components/Order.vue";
import Tracking from "../../../sale/views/components/Tracking.vue";

export default {
  components: {
    Order,
    Tracking,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        // { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        {
          key: "logs",
          name: "Logs",
          icon: "cis-note",
        },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("order.customer_orders.detail.fetch", this.id);
      this.$store.dispatch("order.order_items.push-query", {
        "filter[order_id]": this.id,
      });
    }
  },
  computed: {
    ...mapGetters({
      detail: "order.customer_orders.detail",
      ladingBills: "warehouse.lading_bills_of_orders.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
